import { Duration } from "luxon";
import i18n from "../../i18n";

export const reportTypeIcon = {
	energy_total: "icon-energy success",
	output_power: "icon-power info",
	device_temperature: "icon-heater error",
	humidity_relative: "icon-water info",
	air_quality_index: "icon-air success",
	ambient_temperature: "icon-heater error",
	co2: "icon-particles success",
	ac_power: "icon-energy info",
	lux: "icon-light warn",
	onoff: "icon-online success",
	onoff2: "icon-online success",
	onoff3: "icon-online success",
	onoff4: "icon-online success",
};

export const intervalOptions = [
	{ value: 0, label: "Custom" },
	{ value: -1, label: "All" },
	{ value: 60, label: "1 min" },
	{ value: 600, label: "10 mins" },
	{ value: 1800, label: "30 mins" },
	{ value: 3600, label: "1 hour" },
	{ value: 10800, label: "3 hours" },
	{ value: 21600, label: "6 hours" },
	{ value: 43200, label: "12 hours" },
	{ value: 86400, label: "24 hours" },
];

export const filterByOptions = [
	{ value: "all", label: "all" },
	{ value: "building", label: "building" },
	{ value: "network", label: "network" },
	{ value: "device", label: "device" },
];

export const aggregateOptions = [
	{ value: "SUM", label: "Sum" },
	{ value: "AVG", label: "Average (mean)" },
	{ value: "MIN", label: "Min" },
	{ value: "MAX", label: "Max" },
];

export const groupTypeOptions = [
	{ value: 1, label: "All" },
	{ value: 2, label: "Network" },
	{ value: 3, label: "Group" },
	{ value: 4, label: "Device" },
];

export const groupOptions = [
	{ value: 1, label: "Network 1" },
	{ value: 2, label: "Network 2" },
	{ value: 3, label: "Network 3" },
	{ value: 4, label: "Network 4" },
];

export const tempDummyDevices = [
	{ value: 1, label: "L005" },
	{ value: 2, label: "L002" },
	{ value: 3, label: "L003" },
	{ value: 4, label: "L004" },
];

export const formatIntervalForReportCriteria = (interval) => {
	//Check if interval is custom
	const foundInterval = intervalOptions.some((element) => element.value === interval);

	if (!foundInterval) {
		//format custom interval label
		const { hours, minutes } = Duration.fromMillis(interval * 1000)
			.shiftTo("hours", "minutes")
			.toObject();
		const formattedHours = hours > 0 && i18n.t("pageReport.reportCriteria.hour", { count: hours });
		const formattedMinutes = minutes > 0 && i18n.t("pageReport.reportCriteria.minute", { count: minutes });
		if (formattedHours && formattedMinutes) {
			return `${formattedHours} ${i18n.t("common.and")} ${formattedMinutes}`;
		} else {
			return formattedHours ? formattedHours : formattedMinutes;
		}
	} else {
		//return preset interval label
		return i18n.t(`pageReport.timeInterval.${String(interval)}`);
	}
};
